<template>
  <div class="login-section-form-data">
    <form id="login-data-form">
      <div class="block-input">
        <input type="text" name="userEmail" required @keyup="validateInput" />
        <label>Usuario</label>
      </div>
      <div class="block-input" data-label="Contraseña">
        <input type="password" name="userPass" required @keyup="validateInput" />
        <label>Contraseña</label>
      </div>
      <div class="block-input">
        <a class="login-password" :href="$global.dictionary.apps_icons[0].link.replace(/main/, 'restorepass')">¿Olvidaste tu contraseña?</a>
        <input type="nosubmit" value="Ingresar" readonly @click="validateData" />
      </div>
    </form>
  </div>
</template>

<script>
import global from "../../assets/js/global";
import Swal from "sweetalert2";
import router from "../../router";
const nav = JSON.parse(localStorage.getItem("meta-nav"));
export default {
  data: () => {
    return {
      from: { path: nav?.approvedTo ? nav.approvedTo : "/admin" },
    };
  },
  methods: {
    errorInput: function (el) {
      el.classList.add("data-error");
    },
    successInput: function (el) {
      el.classList.remove("data-error");
    },
    validateInput: function (el) {
      const _this = this;
      const _el = el.srcElement ? el.srcElement : typeof el === "object" ? el : el.srcElement;
      const inputName = _el.getAttribute("name");
      const nextEl = document.querySelector('input[name="userPass"]');
      const _val = _el.value ? _el.value.trim() : "";

      if (el.keyCode === 13 && inputName === "userEmail" && _val) {
        nextEl.focus();
      } else if (el.keyCode === 13 && inputName === "userPass" && _val) {
        _this.validateData();
        return;
      }

      if (_el.type === "text") {
        _val ? _this.successInput(_el) : _this.errorInput(_el);
      } else if (_el.type === "email") {
        global.validEmail(_val) ? _this.successInput(_el) : _this.errorInput(_el);
      } else if (_el.type === "password") {
        _val.length >= 4 ? _this.successInput(_el) : _this.errorInput(_el);
      }
    },
    validateData: function () {
      const _this = this;
      const fmInputs = document.querySelectorAll(".login-section-form-data input");
      [].forEach.call(fmInputs, function (el) {
        if (el.hasAttribute("required")) {
          _this.validateInput(el);
        }
      });
      const dataErrors = document.querySelectorAll(".login-section-form-data .data-error");
      if (dataErrors.length) {
        _this.loginError("por favor revise los datos ingresados");
      } else {
        _this.sendLogin();
      }
    },
    sendLogin: function () {
      const user = {
        email: document.querySelector('[name="userEmail"]').value,
        password: document.querySelector('[name="userPass"]').value,
      };
      this.$root.$emit("userLogin", { user });
      this.dataSending();
    },
    dataSending: function () {
      Swal.fire({
        title: "Entrando",
        text: "Sus datos están siendo validados",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
    loginError: function (message) {
      Swal.fire({
        title: "Error",
        html: message,
        icon: "error",
        confirmButtonText: "Revisar",
        timerProgressBar: true,
        confirmButtonColor: "#bd0909",
      });
    },
    loginResult: function (data) {
      if (data.status) {
        localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, data.token);
        this.$store.dispatch("updateUser");
        router.push(this.from);
        Swal.close();
      } else {
        this.loginError(data.error);
      }
    },
  },
  mounted: function () {
    this.$root.$on("loginResult", (data) => {
      this.loginResult(data);
    });
  },
};
</script>
<style lang="scss">
.login-section-form-data .block-input input[type="nosubmit"] {
  border-radius: 5px;
  border: none;
}
.login-password {
  cursor: pointer;
  font-size: 14px;
  color: black;
}
</style>
